function debounce(func, delay) {
  let debounceTimer;

  function debounced(...args) {
    const context = this;

    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
  
  debounced.cancel = () => {
    clearTimeout(debounceTimer);
  };
  
  return debounced;
}

export default debounce;
