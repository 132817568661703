import slidesLiveDomain from './slideslive_domain';

function fetchUppyUploadParametersForAttachmentUpload(uppy, uppyFile) {
  const uploadParametersEndpoint = `https://studio.${slidesLiveDomain()}/api/attachment_uploads/v1/presign`;

  return fetch(uploadParametersEndpoint, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      file_name: uppyFile.name,
      content_type: uppyFile.type,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.data.file_meta) {
        uppy.setFileMeta(uppyFile.id, response.data.file_meta);
      }

      return response.data.presigned_upload;
    });
}

export default fetchUppyUploadParametersForAttachmentUpload;
